export let companyRegistration = {
  regType: "COMPANY",
  eventChoice: "",
  companyType: "",
  companyName: "",
  companyAddress: "",
  companyApartment: "",
  companyCity: "",
  companyStateId: "",
  companyStateName: "",
  companyZip: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  cellPhone: "",
  stem: {
    science: 0,
    technology: 0,
    engineering: 0,
    math: 0
  },
  msEventType: "",
  msInformationFair: {
    numberOfTables: "",
    isElectricityNeeded: ""
  },
  msInteractive: {
    description: "",
    classroomStyle: "",
    isAdditionalTablesNeeded: "",
    numberOfTables: "",
    isProjectorNeeded: "",
    isInternetNeeded: "",
    cables: "",
    isExtensionCordNeeded: "",
    isLunchNeeded: "",
    lunchTypeId: "",
    lunchTypeName: ""
  },
  hsNumberOfTables: "",
  hsIsElectricityNeeded: "",
  isSponsor: "",
  sponsorshipType: "",
  tShirtSizeId: "",
  tShirtSizeName: "",
  companyLogo: "",
  companyLogoEPS: "",
  participants: []
}

export const participant = Object.freeze({
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  isLunchNeeded: "",
  lunchTypeId: "",
  lunchTypeName: "",
  tShirtSizeId: "",
  tShirtSizeName: ""
})