<template>
  <b-container>
    <div class="text-banner text-center">
      <h2><strong>Attachment Download</strong></h2>
    </div>
    <div>
      <p><strong><span class="text-danger">{{errorMsg}}</span></strong></p>
    </div>
    <b-row>
      <b-col cols="12" lg="8" xl="6">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Registration ID" v-model="searchPrefix">
          <b-button @click="listAttachments">List Attachments</b-button>
        </div>
      </b-col>
      <b-col cols="12" class="mt-3">
        <p v-for="(attachment, index) in attachmentList" v-bind:key="index">
          <a :href="attachment" @click.prevent="downloadAttachment(attachment)">{{attachment}}</a>
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import dataService from '../services/data.service';

  export default {
    data: function() {
      return {
        searchPrefix: '',
        attachmentList: [],
        errorMsg: ''
      };
    },
    async mounted (){
      if(this.$route.query.regId && this.$route.query.attachmentType && this.$route.query.fileName) {
        this.searchPrefix = `${this.$route.query.regId}/${this.$route.query.attachmentType}/${this.$route.query.fileName}`;
        this.listAttachments();
        await this.downloadAttachment(this.searchPrefix);
      } else if (this.$route.query.regId) {
        this.searchPrefix = `${this.$route.query.regId}`;
        this.listAttachments();
      }
    },

    methods: {
      listAttachments: async function () {
        try {
          let response = await dataService.listAttachments(this.searchPrefix);
          if (response && response.data) {
            this.attachmentList = response.data.data;
            this.errorMsg = '';
          }
        } catch (error) {
           this.errorMsg = error;
        }
      },

      downloadAttachment: async function (attachmentPath) {
        try {
          let attachment = attachmentPath.split('/');
          let downloadResponse = await dataService.downloadFile(attachment[0], attachment[1], attachment[2]);
            if (downloadResponse.data && downloadResponse.data.code == 0) {
              //workaround to download file requested from API
              if(!window.navigator.msSaveOrOpenBlob) {
                // BLOB NAVIGATOR
                const url = window.URL.createObjectURL(new Blob([Buffer.from(downloadResponse.data.data.split(",")[1], 'base64')]));
                let link = document.getElementById('download-link');
                if(link == undefined) {
                  link = document.createElement('a');
                  link.setAttribute('id', 'download-link');
                  document.body.appendChild(link);
                }
                link.href = url;
                link.setAttribute('download', attachment[2]);
                link.click();
              } else {
                // BLOB FOR EXPLORER 11
                window.navigator.msSaveOrOpenBlob(new Blob([Buffer.from(downloadResponse.data.data.split(",")[1], 'base64')]), attachment[2]);
              }
              this.errorMsg = '';
            } else {
              this.errorMsg = downloadResponse.data.message || 'Internal Server Error!';
            }
        } catch (error) {
           this.errorMsg = error;
        }
      }
    }
  }

</script>