<template>
  <b-navbar class="navbar-custom" fixed="top" toggleable>
    <b-navbar-brand>
      <router-link :to="{name: 'home'}"><img class="gy_logo" src="../assets/img/goodyear_stem_logo.png" alt="Goodyear STEM Career Day logo"></router-link>
    </b-navbar-brand>

    <b-navbar-toggle target="navbar-toggle-collapse" class="ml-auto mx-2 my-2 d-block d-lg-none button-menu">
      <template #default="{ expanded }">
        <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
        <b-icon v-else icon="chevron-bar-down"></b-icon>
      </template>
    </b-navbar-toggle>

    <b-collapse id="navbar-toggle-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item><Menu/></b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
  import Menu from './Menu.vue'

  export default {
    components: {
      Menu
    }
  }
</script>
