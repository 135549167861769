import axios from 'axios';
import { msalPluginInstance } from "../plugins/MSALPlugin"
import router from '../router';

const getTokenAndCallApi = async (config) => {
  return msalPluginInstance.acquireToken(async (access_token) => {
    //Azure SWA replaces 'authorization' header with its own. So we use custom one.
    config.headers = {
      'x-custom-authorization':  `Bearer ${access_token}`
    };
    try {
      return await axios(config);
    } catch (err) {
      console.log(err);
      if(err.response.status === 403){
        router.push({ name: 'forbidden'});
      } else {
        //do we need to handle error 401? 
      }
      //return data to avoid errors in console
      return {data: {data: {length: 0}}};
      //or just rethrow error?
      //throw err;
    }
  });
};

export default {

  // GET requests
  
  /**
   * Get list of Catalog items by Category
   */
  async getCatalogItemsByCategory(category) {
    return axios.get(`/api/catalog-items/${category}`).catch(error => { throw error } );
  },

  /**
   * Get list of Middle School Groups
   */
   async getMSGroups() {
    return axios.get('/api/middle-school-groups').catch(error => { throw error } );
  },

  /**
   * Get Registration data
   * 
   * @param regId Registration id
   * @param email User email
   */
  async getRegistrationData(regId, email) {
    return getTokenAndCallApi({url: `/api/registrations/${regId}`, method: 'GET', params: {email: email}});
  },

  /**
   * Get list of Middle School Individual Registrations
   */
  async getMSIndRegList() {
    return getTokenAndCallApi({url: '/api/ms-ind-reg-list', method: 'GET'});
  },

  /**
   * Export Middle School Individual Registrations to XLSX
   */
  async exportMSIndRegList() {
    return getTokenAndCallApi({url: '/api/ms-ind-reg-export', method: 'GET', responseType: 'blob'});
  },

  /**
   * Get list of Middle School Group Registrations
   */
  async getMSGroupRegList() {
    return getTokenAndCallApi({url: '/api/ms-group-reg-list', method: 'GET'});
  },

  /**
   * Export Middle School Group Registrations to XLSX
   */
  async exportMSGroupRegList() {
    return getTokenAndCallApi({url: '/api/ms-group-reg-export', method: 'GET', responseType: 'blob'});
  },

  /**
   * Get list of High School Individual Registrations
   */
  async getHSIndRegList() {
    return getTokenAndCallApi({url: '/api/hs-ind-reg-list', method: 'GET'});
  },

  /**
   * Export High School Individual Registrations to XLSX
   */
  async exportHSIndRegList() {
    return getTokenAndCallApi({url: '/api/hs-ind-reg-export', method: 'GET', responseType: 'blob'});
  },

  /**
   * Get list of High School Chaperone Registrations
   */
  async getHSChaperoneRegList() {
    return getTokenAndCallApi({url: '/api/hs-chaperone-reg-list', method: 'GET'});
  },

  /**
   * Export High School Chaperone Registrations to XLSX
   */
  async exportHSChaperoneRegList() {
    return getTokenAndCallApi({url: '/api/hs-chaperone-reg-export', method: 'GET', responseType: 'blob'});
  },

  /**
   * Get list of Company Registrations
   */
  async getCompanyRegList() {
    return getTokenAndCallApi({url: '/api/company-reg-list', method: 'GET'});
  },

  /**
   * Export Company Registrations to XLSX
   */
  async exportCompanyRegList() {
    return getTokenAndCallApi({url: '/api/company-reg-export', method: 'GET', responseType: 'blob'});
  },

  /**
   * Get list of Scholarship Applications
   */
  async getScholarshipApplicationList() {
    return getTokenAndCallApi({url: '/api/scholarship-list', method: 'GET'});
  },

  /**
   * Export Scholarship Applications to XLSX
   */
  async exportScholarshipApplicationList() {
    return getTokenAndCallApi({url: '/api/scholarship-export', method: 'GET', responseType: 'blob'});
  },

  /**
   * Get MS Group Members
   */
   async getMSGroupMembers(regId) {
    return getTokenAndCallApi({url: `/api/ms-group-reg/${regId}/members`, method: 'GET'});
  },

  /**
   * Get MS Group Pending Removal Members
   */
   async getMSGroupMembersPending() {
    return getTokenAndCallApi({url: '/api/ms-group-reg/members/pending', method: 'GET'});
  },

  // POST requests

  async verifyGroupCode(groupId, groupCode) {
    return getTokenAndCallApi({url: '/api/verify-group-code', method: 'POST', data: {groupId: groupId, groupCode: groupCode}});
  },

  // PUT requests

  /**
   * Update middle school student registration
   * 
   * @param regId Registration id
   * @param data Student data object
   */
  async updateMSIndReg(regId, data) {
    return getTokenAndCallApi({url: `/api/ms-ind-reg/${regId}`, method: 'PUT', data: JSON.stringify(data)});
  },

  /**
   * Update middle school group registration
   * 
   * @param regId Registration id
   * @param data Group data object
   */
  async updateMSGroupReg(regId, data) {
    return getTokenAndCallApi({url: `/api/ms-group-reg/${regId}`, method: 'PUT', data: JSON.stringify(data)});
   },

  /**
   * Update high school student registration
   * 
   * @param regId Registration id
   * @param data Student data object
   */
  async updateHSIndReg(regId, data) {
    return getTokenAndCallApi({url: `/api/hs-ind-reg/${regId}`, method: 'PUT', data: JSON.stringify(data)});
  },

  /**
   * Update high school chaperone registration
   * 
   * @param regId Registration id
   * @param data Chaperone data object
   */
  async updateHSChaperoneReg(regId, data) {
    return getTokenAndCallApi({url: `/api/hs-chaperone-reg/${regId}`, method: 'PUT', data: JSON.stringify(data)});
  },

  /**
   * Update company registration
   * 
   * @param regId Registration id
   * @param data Comapany data object
   */
  async updateCompanyReg(regId, data) {
    return getTokenAndCallApi({url: `/api/company-reg/${regId}`, method: 'PUT', data: JSON.stringify(data)});
  },

  /**
   * Update Schoalrship Application
   * 
   * @param regId Application id
   * @param data Scholarship Application data object
   */
  async updateScholarshipApplication(regId, data) {
    return getTokenAndCallApi({url: `/api/scholarships/${regId}`, method: 'PUT', data: JSON.stringify(data)});
  },

  /**
   * Update Registration Status
   * 
   * @param data MS Group member status data
   */
  async updateRegistrationStatus(data) {
    return getTokenAndCallApi({url: `/api/registration-status`, method: 'PUT', data: data});
  },

  /**
   * Move MS student to another group
   * 
   * @param data Student id, email, id of new group
   */
   async changeGroup(data) {
    return getTokenAndCallApi({url: `/api/ms-group-reg/members/change`, method: 'PUT', data: data});
  },

  // Attachments

  async uploadFile(fileData) {
    return getTokenAndCallApi({url: "/api/files", method: 'POST', data: [fileData]});
  },

  async uploadFiles(attachments) {
    return getTokenAndCallApi({url: "/api/files", method: 'POST', data: attachments});
  },

  async downloadFile(regId, attachmentType, fileName) {
    return getTokenAndCallApi({url: `/api/files/${regId}/${attachmentType}/${fileName}`, method: 'GET'});
  },

  async deleteFile(regId, attachmentType, fileName) {
    return getTokenAndCallApi({url: `/api/files/${regId}/${attachmentType}/${fileName}`, method: 'DELETE'});
  },

  async listAttachments(searchPrefix) {
    return getTokenAndCallApi({url: `/api/files`, method: 'GET', params: {searchPrefix: searchPrefix}});
  },

  async readAsDataURL(file) {
    return new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = function() {
        return resolve({data: fileReader.result, name: file.name, size: file.size, type: file.type});
      }
      fileReader.readAsDataURL(file);
    })
  }
}