import moment from 'moment'

export default {
  regType: "MSIND",
  firstName: "",
  lastName: "",
  email: "",
  schoolId: "",
  otherSchoolName: "",
  gradeLevelId: "",
  lunchId: "",
  tShirtSizeId: "",
  favouriteSubjects: {
    science: 0,
    technology: 0,
    engineering: 0,
    math: 0
  },
  hasAttended: 0,
  parentGuardian: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    willBeAttending: "",
    willAddParentBeAttending: "",
    addParentFirstName: "",
    addParentLastName: ""
  },
  emergencyContact: {
    firstName: "",
    lastName: "",
    phoneNumber: ""
  },
  groupId: "",
  consentWillBeSubmittedBySchool: "",
  stemDayConsent: {
    parentGuardianName: "",
    relation: "",
    studentName: "",
    parentGuardianElSignature: "",
    date: moment().format('MM/DD/YYYY')
  }
}