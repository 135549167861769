export const msalConfig = {
    auth: {
        clientId: 'e738f48c-055e-48ff-8b65-f02955a73404', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/939e8966-9285-4a9a-9f04-0887efe8aae0', // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: `${window.location.origin}/logout`, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
}

export const msalScopes = ["api://8c3d5ae7-094a-4c85-a58e-6ebc56a464ed/user_impersonation"]

export const roles = {
    ADMIN: "STEMCareerDayAdmins"
}