export const states = [
    { "id":"1", "postal": "AL", "title": "Alabama", "isActive": 1 },
    { "id":"2", "postal": "AK", "title": "Alaska", "isActive": 1 },
    { "id":"3", "postal": "AZ", "title": "Arizona", "isActive": 1 },
    { "id":"4", "postal": "AR", "title": "Arkansas", "isActive": 1 },
    { "id":"5", "postal": "CA", "title": "California", "isActive": 1 },
    { "id":"6", "postal": "CO", "title": "Colorado", "isActive": 1 },
    { "id":"7", "postal": "CT", "title": "Connecticut", "isActive": 1 },
    { "id":"8", "postal": "DE", "title": "Delaware", "isActive": 1 },
    { "id":"9", "postal": "DC", "title": "District Of Columbia", "isActive": 1 },
    { "id":"10", "postal": "FL", "title": "Florida", "isActive": 1 },
    { "id":"11", "postal": "GA", "title": "Georgia", "isActive": 1 },
    { "id":"12", "postal": "HI", "title": "Hawaii", "isActive": 1 },
    { "id":"13", "postal": "ID", "title": "Idaho", "isActive": 1 },
    { "id":"14", "postal": "IL", "title": "Illinois", "isActive": 1 },
    { "id":"15", "postal": "IN", "title": "Indiana", "isActive": 1 },
    { "id":"16", "postal": "IA", "title": "Iowa", "isActive": 1 },
    { "id":"17", "postal": "KS", "title": "Kansas", "isActive": 1 },
    { "id":"18", "postal": "KY", "title": "Kentucky", "isActive": 1 },
    { "id":"19", "postal": "LA", "title": "Louisiana", "isActive": 1 },
    { "id":"20", "postal": "ME", "title": "Maine", "isActive": 1 },
    { "id":"21", "postal": "MD", "title": "Maryland", "isActive": 1 },
    { "id":"22", "postal": "MA", "title": "Massachusetts", "isActive": 1 },
    { "id":"23", "postal": "MI", "title": "Michigan", "isActive": 1 },
    { "id":"24", "postal": "MN", "title": "Minnesota", "isActive": 1 },
    { "id":"25", "postal": "MS", "title": "Mississippi", "isActive": 1 },
    { "id":"26", "postal": "MO", "title": "Missouri", "isActive": 1 },
    { "id":"27", "postal": "MT", "title": "Montana", "isActive": 1 },
    { "id":"28", "postal": "NE", "title": "Nebraska", "isActive": 1 },
    { "id":"29", "postal": "NV", "title": "Nevada", "isActive": 1 },
    { "id":"30", "postal": "NH", "title": "New Hampshire", "isActive": 1 },
    { "id":"31", "postal": "NJ", "title": "New Jersey", "isActive": 1 },
    { "id":"32", "postal": "NM", "title": "New Mexico", "isActive": 1 },
    { "id":"33", "postal": "NY", "title": "New York", "isActive": 1 },
    { "id":"34", "postal": "NC", "title": "North Carolina", "isActive": 1 },
    { "id":"35", "postal": "ND", "title": "North Dakota", "isActive": 1 },
    { "id":"36", "postal": "OH", "title": "Ohio", "isActive": 1 },
    { "id":"37", "postal": "OK", "title": "Oklahoma", "isActive": 1 },
    { "id":"38", "postal": "OR", "title": "Oregon", "isActive": 1 },
    { "id":"39", "postal": "PA", "title": "Pennsylvania", "isActive": 1 },
    { "id":"40", "postal": "RI", "title": "Rhode Island", "isActive": 1 },
    { "id":"41", "postal": "SC", "title": "South Carolina", "isActive": 1 },
    { "id":"42", "postal": "SD", "title": "South Dakota", "isActive": 1 },
    { "id":"43", "postal": "TN", "title": "Tennessee", "isActive": 1 },
    { "id":"44", "postal": "TX", "title": "Texas", "isActive": 1 },
    { "id":"45", "postal": "UT", "title": "Utah", "isActive": 1 },
    { "id":"46", "postal": "VT", "title": "Vermont", "isActive": 1 },
    { "id":"47", "postal": "VA", "title": "Virginia", "isActive": 1 },
    { "id":"48", "postal": "WA", "title": "Washington", "isActive": 1 },
    { "id":"49", "postal": "WV", "title": "West Virginia", "isActive": 1 },
    { "id":"50", "postal": "WI", "title": "Wisconsin", "isActive": 1 },
    { "id":"51", "postal": "WY", "title": "Wyoming", "isActive":  1 }
]