<template>
  <div class="gr-border container px-3">
    <div class="text-end">
      <router-link :to="{name: 'ms-ind-reg-list'}"><button type="button" class="btn-close" aria-label="Close"></button></router-link>
    </div>
    <ValidationObserver ref="form" v-slot="{ valid }">
      <form>
        <fieldset :disabled="isBusy">
          <div class="row">
            <div class="text-center gr-bak col-sm-12">
              <!---
              <h1 class="dk-blue upper" v-if="!isEdit"><strong>Middle School STEM Career Day {{$stemYear}}<br/>Individual Registration</strong></h1>
              --->
              <h1 class="dk-blue upper" v-if="isEdit"><strong>Middle School STEM Career Day {{$stemYear}}<br/>Edit Individual Registration</strong></h1>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>1. Student Information</h3>
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="first-name"><span class="text-danger">*</span>First Name</label>
                  <input type="text" name="first-name" id="first-name" class="form-control" :class="classes" v-model="formData.firstName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="last-name"><span class="text-danger">*</span>Last Name</label>
                  <input type="text" name="last-name" id="last-name" class="form-control" :class="classes" v-model="formData.lastName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider vid="email" rules="required|email" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="email"><span class="text-danger">*</span>Email Address</label>
                  <input type="email" name="email" id="email" class="form-control" :class="classes" v-model="formData.email" :disabled="isEdit">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider :rules="{ required: true, email: true, confirmEmail: '@email'}" v-slot="{ classes, errors }" v-if="!isEdit">
                <div class="form-group">
                  <label for="email_confirm"><span class="text-danger">*</span>Confirm Email Address</label>
                  <input type="email" name="email_confirm" id="email_confirm" v-model="emailConfirm" class="form-control" :class="classes">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="ms-school-selector"><span class="text-danger">*</span>School</label>
                  <select id="ms-school-selector" name="ms-school-selector" class="form-select" :class="classes" v-model="formData.schoolId">
                    <option value="">-Select Option-</option>
                    <option value="other">My school is not listed here. I will enter school in next field.</option>
                    <option v-for="school in schools" v-bind:value="school.id" v-bind:key="school.id">
                      {{ school.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-12">
              <!-- Quick fix to only show checkbox if the selected school is an Akron Public School -->
              <!-- Nice to have: cross-check against database: if school is Akron Public School -->
              <!-- Using TEST ids -->
              <div class="form-check form-check-inline ml-1">
                <input class="form-check-input" type="checkbox" id="consent-will-be-submitted-by-school" true-value="1" false-value="0" 
                  v-model="formData.consentWillBeSubmittedBySchool" 
                  v-show="formData.schoolId == 'e371a515-75dc-4357-a383-e77146f8aa89' || 
                  formData.schoolId == 'b6f0f5c5-9506-4a38-bb60-7d84713eb720' || 
                  formData.schoolId == 'e6725206-14e0-4f79-ad26-a724c3c607e5' || 
                  formData.schoolId == 'a9f778e9-c15f-4b91-8a31-bd97d27e60de' || 
                  formData.schoolId == '8409e113-4e88-4208-8651-91e5a115c8f1' || 
                  formData.schoolId == 'ecc1fdf5-06c5-4436-9f01-731dab942be2' || 
                  formData.schoolId == 'dbdc0b05-1bb7-49a9-983f-f13dd1339ef5' || 
                  formData.schoolId == '17075ce7-bd72-438c-a77b-192bcf5d3da6' ||
                  formData.schoolId == '4925dec3-23d7-4971-90d5-f80c68dc6846'">
                <label class="form-check-label" for="consent-will-be-submitted-by-school" 
                v-show="formData.schoolId == 'e371a515-75dc-4357-a383-e77146f8aa89' || 
                  formData.schoolId == 'b6f0f5c5-9506-4a38-bb60-7d84713eb720' || 
                  formData.schoolId == 'e6725206-14e0-4f79-ad26-a724c3c607e5' || 
                  formData.schoolId == 'a9f778e9-c15f-4b91-8a31-bd97d27e60de' || 
                  formData.schoolId == '8409e113-4e88-4208-8651-91e5a115c8f1' || 
                  formData.schoolId == 'ecc1fdf5-06c5-4436-9f01-731dab942be2' || 
                  formData.schoolId == 'dbdc0b05-1bb7-49a9-983f-f13dd1339ef5' || 
                  formData.schoolId == '17075ce7-bd72-438c-a77b-192bcf5d3da6' ||
                  formData.schoolId == '4925dec3-23d7-4971-90d5-f80c68dc6846' ">I'm an Akron Public School student whose parental consent form will be submitted by my school.</label>
              </div>
              <!-- Using PROD ids -->
              <div class="form-check form-check-inline ml-1">
                <input class="form-check-input" type="checkbox" id="consent-will-be-submitted-by-school" true-value="1" false-value="0" 
                  v-model="formData.consentWillBeSubmittedBySchool" 
                  v-show="formData.schoolId == '9a6a71cf-268d-4522-ba0d-1656fa4e7850' || 
                  formData.schoolId == '0ed127ce-0629-4c0c-8d0e-eff8538fdbf6' || 
                  formData.schoolId == '6443e1e5-8e99-4f2c-a7ff-3fe977ffd380' || 
                  formData.schoolId == 'b11880fc-bdf0-46b3-b036-a4da262c6a3b' || 
                  formData.schoolId == '846e4907-48d3-4688-83fa-a34940f0788d' || 
                  formData.schoolId == 'e623741a-487c-4bed-b209-c01325b5baf3' || 
                  formData.schoolId == 'e74c1367-b080-499f-adae-befe49dc041b' || 
                  formData.schoolId == '092f0660-6e96-430a-bc37-bfbe1139d0b4' || 
                  formData.schoolId == '07ae52f9-e5f5-4d4f-bcc6-917d803b18c8' ">
                <label class="form-check-label" for="consent-will-be-submitted-by-school" 
                v-show="formData.schoolId == '9a6a71cf-268d-4522-ba0d-1656fa4e7850' || 
                  formData.schoolId == '0ed127ce-0629-4c0c-8d0e-eff8538fdbf6' || 
                  formData.schoolId == '6443e1e5-8e99-4f2c-a7ff-3fe977ffd380' || 
                  formData.schoolId == 'b11880fc-bdf0-46b3-b036-a4da262c6a3b' || 
                  formData.schoolId == '846e4907-48d3-4688-83fa-a34940f0788d' || 
                  formData.schoolId == 'e623741a-487c-4bed-b209-c01325b5baf3' || 
                  formData.schoolId == 'e74c1367-b080-499f-adae-befe49dc041b' || 
                  formData.schoolId == '092f0660-6e96-430a-bc37-bfbe1139d0b4' || 
                  formData.schoolId == '07ae52f9-e5f5-4d4f-bcc6-917d803b18c8'" >I'm an Akron Public School student whose parental consent form will be submitted by my school.</label>
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider :rules="{required: formData.schoolId=='other' }" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="school-other"><span v-if="formData.schoolId=='other'" class="text-danger">*</span>School (If not in previous list)</label>
                  <input type="text" name="school-other" id="school-other" class="form-control" :class="classes" v-model="formData.otherSchoolName" :disabled="formData.schoolId !== 'other'">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="grade-level"><span class="text-danger">*</span>Grade Level</label>
                  <select id="grade-level" name="grade-level" class="form-select" :class="classes" v-model="formData.gradeLevelId">
                    <option value="" >-Select Option-</option>
                    <option v-for="gradeLevel in gradeLevels" v-bind:value="gradeLevel.id" v-bind:key="gradeLevel.id">
                      {{ gradeLevel.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="lunch"><span class="text-danger">*</span>Lunch</label>
                  <select id="lunch" name="lunch" class="form-select" :class="classes" v-model="formData.lunchId">
                    <option value="">-Select Option-</option>
                    <option v-for="lunchType in lunchTypes" v-bind:value="lunchType.id" v-bind:key="lunchType.id">
                      {{ lunchType.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="t-shirt-size"><span class="text-danger">*</span>T-Shirt Size</label>
                  <select id="t-shirt-size" name="t-shirt-size" class="form-select" :class="classes" v-model="formData.tShirtSizeId">
                    <option value="" >-Select Option-</option>
                    <option v-for="tShirtSize in tShirtSizes" v-bind:value="tShirtSize.id" v-bind:key="tShirtSize.id">
                      {{ tShirtSize.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <p><strong><span class="text-danger">*</span>Favorite Subjects (Select all that apply)</strong></p>
              <div class="form-check form-check-inline">
                <input type="checkbox" class="form-check-input" id="science" true-value="1" false-value="0" v-model="formData.favouriteSubjects.science">
                <label class="form-check-label" for="science">Science</label>
              </div>
              <div class="form-check form-check-inline">
                <input type="checkbox" class="form-check-input" id="technology" true-value="1" false-value="0" v-model="formData.favouriteSubjects.technology">
                <label class="form-check-label" for="technology">Technology</label>
              </div>
              <div class="form-check form-check-inline">
                <input type="checkbox" class="form-check-input" id="engineering" true-value="1" false-value="0" v-model="formData.favouriteSubjects.engineering">
                <label class="form-check-label" for="engineering">Engineering</label>
              </div>
              <ValidationProvider name="subject" :rules="{ atLeastOne: [formData.favouriteSubjects.science,formData.favouriteSubjects.technology,formData.favouriteSubjects.engineering,formData.favouriteSubjects.math] }" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input type="checkbox" class="form-check-input" id="math" true-value="1" false-value="0" v-model="formData.favouriteSubjects.math">
                  <label class="form-check-label" for="math">Math</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
            <div class="col-12">
              <strong><span class="text-danger">*</span>Have you attended a Goodyear STEM Day event in the past?</strong>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="has-attended" id="has-attended-yes" v-model="formData.hasAttended" value="1">
                <label class="form-check-label" for="has-attended-yes">Yes</label>
              </div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="has-attended" id="has-attended-no" v-model="formData.hasAttended" value="0">
                  <label class="form-check-label" for="has-attended-no">No</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>2. Parent/Legal Guardian Information</h3>
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="parent-first-name"><span class="text-danger">*</span>First Name</label>
                  <input type="text" name="parent-first-name" id="parent-first-name" class="form-control" :class="classes" v-model="formData.parentGuardian.firstName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="parent-last-name"><span class="text-danger">*</span>Last Name</label>
                  <input type="text" name="parent-last-name" id="parent-last-name" class="form-control" :class="classes" v-model="formData.parentGuardian.lastName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider vid="parentEmail" rules="required|email" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="parent-email"><span class="text-danger">*</span>Email Address</label>
                  <input type="email" name="parent-email" id="parent-email" class="form-control" :class="classes" v-model="formData.parentGuardian.email">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider :rules="{ required: true, email: true, confirmEmail: '@parentEmail'}" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="parent-email-confirm"><span class="text-danger">*</span>Confirm Email Address</label>
                  <input type="email" name="parent-email-confirm" id="parent-email-confirm" class="form-control" :class="classes" v-model="parentEmailConfirm">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="parent-phone-number"><span class="text-danger">*</span>Phone Number</label>
                  <input type="text" name="parent-phone-number" id="parent-phone-number" class="form-control" :class="classes" v-model="formData.parentGuardian.phoneNumber">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <strong><span class="text-danger">*</span>Will this parent/legal guardian be attending?</strong>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="parent-attending" id="parent-attending-yes" v-model="formData.parentGuardian.willBeAttending" value="1">
                <label class="form-check-label" for="parent-attending-yes">Yes</label>
              </div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="parent-attending" id="parent-attending-no" v-model="formData.parentGuardian.willBeAttending" value="0">
                  <label class="form-check-label" for="parent-attending-no">No</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
            <div class="col-12" v-show="formData.parentGuardian.willBeAttending==1">
              <div class="row">
                <div class="col-12">
                  <strong><span class="text-danger">*</span>Will an additional parent/legal guardian be attending?</strong>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="add-parent-attending" id="add-parent-attending-yes" v-model="formData.parentGuardian.willAddParentBeAttending" value="1">
                    <label class="form-check-label" for="add-parent-attending-yes">Yes</label>
                  </div>
                  <ValidationProvider :rules="{required: formData.parentGuardian.willBeAttending==1}" v-slot="{errors}">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="add-parent-attending" id="add-parent-attending-no" v-model="formData.parentGuardian.willAddParentBeAttending" value="0">
                      <label class="form-check-label" for="add-parent-attending-no">No</label>
                    </div>
                    <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                  </ValidationProvider>
                </div>
                <div class="col-12" v-show="formData.parentGuardian.willAddParentBeAttending==1">
                  <div class="row">
                    <div class="col-md-6">
                      <ValidationProvider :rules="{required: formData.parentGuardian.willAddParentBeAttending==1}" v-slot="{ classes, errors }">
                        <div class="form-group">
                          <label for="add-parent-first-name"><span class="text-danger">*</span>Additional Parent/Legal Guardian First Name</label>
                          <input type="text" name="add-parent-first-name" id="additional_prnt_fn" class="form-control" :class="classes" v-model="formData.parentGuardian.addParentFirstName"/>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider :rules="{required: formData.parentGuardian.willAddParentBeAttending==1}" v-slot="{ classes, errors }">
                        <div class="form-group">
                          <label for="add-parent-last-name"><span class="text-danger">*</span>Additional Parent/Legal Guardian Last Name</label>
                          <input type="text" name="add-parent-last-name" id="additional_prnt_ln" class="form-control" :class="classes" v-model="formData.parentGuardian.addParentLastName"/>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>3. Emergency Contact for Day of Event</h3>
              </div>
            </div>
            <div class="col-12">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="ec-is-same-as-parent" name="ec-is-same-as-parent" v-model="isParentEmergencyContact">
                <label class="form-check-label" for="ec-is-same-as-parent">Same as Parent/Legal Guardian information</label>
              </div>
            </div>
            <div class="col-12" v-if="!isParentEmergencyContact">
              <div class="row">
                <div class="col-md-6">
                  <ValidationProvider rules="required" v-slot="{ classes, errors }">
                    <div class="form-group">
                      <label for="ec-first-name"><span class="text-danger">*</span>First Name</label>
                      <input type="text" name="ec-first-name" id="ec-first-name" class="form-control" :class="classes" v-model="formData.emergencyContact.firstName">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider rules="required" v-slot="{ classes, errors }">
                    <div class="form-group">
                      <label for="ec-last-name"><span class="text-danger">*</span>Last Name</label>
                      <input type="text" name="ec-last-name" id="ec-last-name" class="form-control" :class="classes" v-model="formData.emergencyContact.lastName">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider rules="required" v-slot="{ classes, errors }">
                    <div class="form-group">
                      <label for="ec-phone-number"><span class="text-danger">*</span>Phone Number</label>
                      <input type="text" name="ec-phone-number" id="ec-phone-number" class="form-control" :class="classes" v-model="formData.emergencyContact.phoneNumber">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>4. Join a Group</h3>
              </div>
            </div>
            <div class="form-group col-12">
              <router-link :to="{name: 'contact', query: {accordionId: 'accordion-2'}}" target= '_blank'>Questions about Group registration?</router-link>
            </div>
            <div class="col-12">
              <strong><span class="text-danger">*</span>Are you registering as part of a group?</strong>
              <a class="btn-popover px-2" tabindex="0" v-b-popover.click.blur.top="'Parents/Teachers may create groups and provide you with the information to join. You do not have to join a group to attend. You may login later to complete this part of your registration'">
                <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
              </a>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="is-part-of-group" id="is-part-of-group-yes" v-model="isPartOfGroup" v-bind:value="true">
                <label class="form-check-label" for="is-part-of-group-yes">Yes</label>
              </div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="is-part-of-group" id="is-part-of-group-no" v-model="isPartOfGroup" v-bind:value="false">
                  <label class="form-check-label" for="is-part-of-group-no">No</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
            <div v-if="isPartOfGroup" class="col-12">
              <div class="row">
                <div class="col-md-6">
                  <ValidationProvider :rules="{required: isPartOfGroup }" v-slot="{ classes, errors }">
                    <div class="form-group">
                      <label for="group-name"><span class="text-danger">*</span>Group Name</label>
                      <a class="btn-popover px-2" tabindex="0" v-b-popover.click.blur.top="'If the group you are looking to join is not registered yet, you may login later to complete this part of your registration.'">
                        <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                      </a>
                      <select id="group-name" name="group-name" class="form-select" :class="classes" v-model="formData.groupId" @change="clearGroupCode()">
                        <option value="" >-Select Option-</option>
                        <option v-for="group in groups" v-bind:value="group.id" v-bind:key="group.id">
                          {{ group.groupName }}
                        </option>
                      </select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider vid="groupCode" :rules="{required: isPartOfGroup }" v-slot="{ classes, errors }">
                    <div class="form-group">
                      <label for="group-code"><span class="text-danger">*</span>Group Code</label>
                      <a class="btn-popover px-2" tabindex="0" v-b-popover.click.blur.top="'If you do not have your group password, you may log back in and complete at a later date.  Your group password should be communicated to you by your group leader. Please note that group codes are case sensitive.'">
                        <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                      </a>
                      <input type="text" name="group-code" id="group-code" class="form-control" :class="classes" v-model="groupCode">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-3">
                  <button class="btn btn-yellow" v-on:click="verifyGroupCode">VERIFY</button>
                </div>
                <div class="col-md-9">
                  <p v-if="isGroupCodeValid"><strong><span class="text-success">Group Code is valid</span></strong></p>
                </div>
                <div class="col-12">
                  <p v-if="isGroupCodeInvalid"><b-icon-exclamation-circle-fill variant="danger" class="mr-2"></b-icon-exclamation-circle-fill>Please confirm group name and group code with your group leader. You may choose NO for now and log back in to join a group after submission. Note: Group codes are case sensitive.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>5. STEM Day consent form</h3>
              </div>
            </div>
            <div class="col-12">
              <strong><span class="text-danger">*</span>Is a parent/legal guardian present to complete the STEM Day consent form?</strong>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="consent-is-prnt-present" id="consent-is-prnt-present-yes" v-model="isContestFormSubmitted" v-bind:value="true">
                <label class="form-check-label" for="consent-is-prnt-present-yes">Yes</label>
              </div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="consent-is-prnt-present" id="consent-is-prnt-present-no" v-model="isContestFormSubmitted" v-bind:value="false">
                  <label class="form-check-label" for="consent-is-prnt-present-no">No</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
            <div class="col-12">
              <p class="small">
                <i>You must have a parent/legal guardian fill out the consent form to submit your registration.</i>
              </p>
            </div>
            <div class="form-group col-12 consent-container" v-if="isContestFormSubmitted && formData.consentWillBeSubmittedBySchool!='1'">
              <p><strong><span class="text-danger">*</span>Parent/Legal Guardian please view and sign the consent form below:</strong></p>
              <div class="consent-form-container">
                <h3>Consent and Release Form</h3>
                <p>I, <ValidationProvider :rules="{required: isContestFormSubmitted}"  v-slot="{classes}"><input type="text" name="consent-name" id="consent-name" class="form-control consent-content" :class="classes" placeholder="*Name of Parent/Guardian" v-model="formData.stemDayConsent.parentGuardianName" /></ValidationProvider>, am the 
                <ValidationProvider :rules="{required: isContestFormSubmitted}"  v-slot="{classes}"><input type="text" name="consent-relation" id="consent-relation" class="has-error form-control consent-content" :class="classes" placeholder="*Father, Mother, Custodial Parent, Legal Guardian" v-model="formData.stemDayConsent.relation" /></ValidationProvider> of 
                <ValidationProvider :rules="{required: isContestFormSubmitted}"  v-slot="{classes}"><input type="text" name="consent-student-name" id="consent-student-name" class="form-control consent-content" :class="classes" placeholder="*Student's Name" v-model="formData.stemDayConsent.studentName" /></ValidationProvider>.</p>
                <p>The above-mentioned child is permitted to participate in Goodyear's STEM Career Day. On behalf of my child and myself, I hereby assume all risks in connection with Goodyear's STEM Career Day and I further release, discharge, and/or otherwise indemnify The Goodyear Tire &amp; Rubber Company, Firestone High School, and Litchfield Middle School as well as their officers, employees and volunteers from all claims, judgments, liability by or on behalf of myself and my child for any injury or damage due to the child's participation in Goodyear's STEM Career Day, including all risks connected therewith whether foreseen or unforeseen. </p>
                <p>I hereby acknowledge that the materials submitted by my child to The Goodyear Tire &amp; Rubber Company such as (but not limited to) part of an application in the Rube Goldberg Challenge<sup>&trade;</sup> for Goodyear's STEM Career Day, including any video which may include footage of my child, may appear on a Goodyear website or be otherwise distributed in some form of social media.</p>
                <p>I hereby acknowledge that my child is going to be given access to an account on the website for event participants with the opportunity to log in and change the registration information and password.</p>
                <p>The Goodyear Tire &amp; Rubber Company and its associated companies, The University of Akron, Firestone High School, and Litchfield Middle School may make or permit use of, and may copyright, for publicity or other business purposes, the photographs or video taken on or prior to Goodyear's STEM Career Day of me and/or my son/daughter/legal ward and/or make or permit use of my (or their) written or recorded statements, either verbatim or edited or abridged, in letter form or otherwise, all in such form and manner as said The Goodyear Tire &amp; Rubber Company and The University of Akron may desire and either in conjunction with my name or their name or apart from it.</p>
                <p><ValidationProvider :rules="{required: isContestFormSubmitted}"  v-slot="{classes}"><input type="text" name="consent-signature" id="consent-signature" class="form-control consent-content" :class="classes" v-model="formData.stemDayConsent.parentGuardianElSignature" placeholder="*Parent/Legal Guardian's Electronic Signature"/></ValidationProvider> <ValidationProvider :rules="{required: isContestFormSubmitted, validDate: 'MM/DD/YYYY'}"  v-slot="{classes}"><input type="text" name="consent-date" id="consent-date" class="form-control consent-content" :class="classes" v-model="formData.stemDayConsent.date" readonly /></ValidationProvider></p>
              </div>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-md-4">
              <button class="btn btn-yellow" v-on:click="edit" v-if="isEdit">UPDATE REGISTRATION</button>
            </div>
            <div v-if="isBusy" class="col-md-4 align-self-center text-center">
              <b-spinner></b-spinner>
            </div>
            <div v-else class="col-md-4 mt-2">
              <p v-if="!valid && showErrorMsg"><strong><span class="text-danger">Please check all required fields</span></strong></p>
            </div>
            <div class="col-md-4">
              <router-link :to="{name: 'ms-ind-reg-list'}"><button class="btn btn-yellow">CANCEL</button></router-link>
            </div>
          </div>
        </fieldset>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import moment from 'moment';
  import MSIndRegistration from '../models/ms-ind-registration';
  import dataService from '../services/data.service';
  import { msGradeLevels } from '../data/grade-levels'
  import { tShirtSizes } from '../data/tshirt-sizes'
  import { lunchTypes } from '../data/lunch-types'

  export default {
    props: ['regId','email','ageVerified'],
    data: function() {
      return {
        isBusy: false,
        isEdit: false,
        showErrorMsg: false,
        schools: [],
        gradeLevels: msGradeLevels,
        tShirtSizes: tShirtSizes,
        lunchTypes: lunchTypes,
        formData: JSON.parse(JSON.stringify(MSIndRegistration)), //clone to avoid caching, doesn't work for complex types (e.g. Date)
        emailConfirm: "",
        parentEmailConfirm: "",
        isParentEmergencyContact: false,
        isPartOfGroup: "",
        groups: [],
        groupCode: "",
        isGroupCodeValid: false,
        isGroupCodeInvalid: false,
        isContestFormSubmitted: "",
        hasAttended: "",
      };
    },
    async mounted (){
      this.isBusy = true;
      try {
        if(this.$route.params.regId) {
          this.isEdit = true;
          let regData = await dataService.getRegistrationData(this.$route.params.regId, this.$route.params.email);
          this.formData = regData.data.data;
          this.parentEmailConfirm = this.formData.parentGuardian.email;
          this.isPartOfGroup = this.formData.groupId ? true : false;
          this.isContestFormSubmitted = this.formData.stemDayConsent.parentGuardianName ? true : false;
          if (!this.formData.stemDayConsent.date) {
            this.formData.stemDayConsent.date = moment().format('MM/DD/YYYY');
          }
        } else {
          this.$router.push({ name: 'ms-ind-reg-list' });
        }

        let schoolList = await dataService.getCatalogItemsByCategory("MIDSCH");
        this.schools = schoolList.data.data;

        let groupList = await dataService.getMSGroups();
        this.groups = groupList.data.data;

        this.isBusy = false;

      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },
    methods: {
      edit: async function (event) {
        event.preventDefault();
        this.isBusy = true;

        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showErrorMsg = true;
          this.isBusy = false;
          return;
        }

        this.clearHiddenFields();

        if (this.isPartOfGroup && !this.isGroupCodeValid) {
          this.$refs.form.setErrors({groupCode: ["Please verify Group Code"]});
          this.isBusy = false;
          return;
        }

        if (this.isParentEmergencyContact) {
          this.setParentAsEmergencyContact();
        }

        try {
          await dataService.updateMSIndReg(this.$route.params.regId, this.formData);
          this.$router.push({ name: 'ms-ind-reg-list', params: {refreshTable: true}});
        } catch (error) {
          this.isBusy = false;
          console.log(error);
        }
      },

      clearHiddenFields: function () {
        if (this.formData.schoolId != 'other') {
          this.formData.otherSchoolName = "";
        }

        if (!this.isPartOfGroup) {
          this.formData.groupId = "";
        }

        if (this.formData.parentGuardian.willBeAttending != 1) {
          this.formData.parentGuardian.willAddParentBeAttending = "";
        }
        if (this.formData.parentGuardian.willAddParentBeAttending != 1) {
          this.formData.parentGuardian.addParentFirstName = "";
          this.formData.parentGuardian.addParentLastName = "";
        }

        if (!this.isContestFormSubmitted || this.formData.consentWillBeSubmittedBySchool=='1') {
          this.formData.stemDayConsent.parentGuardianName = "";
          this.formData.stemDayConsent.relation = "";
          this.formData.stemDayConsent.studentName = "";
          this.formData.stemDayConsent.parentGuardianElSignature = "";
          this.formData.stemDayConsent.date = "";
        }
      },

      setParentAsEmergencyContact: function() {
        this.formData.emergencyContact.firstName = this.formData.parentGuardian.firstName;
        this.formData.emergencyContact.lastName = this.formData.parentGuardian.lastName;
        this.formData.emergencyContact.phoneNumber = this.formData.parentGuardian.phoneNumber;
      },

      verifyGroupCode: async function (event) {
        event.preventDefault();
        this.isBusy = true;
        this.isGroupCodeValid = false;
        try {
          let response = await dataService.verifyGroupCode(this.formData.groupId, this.groupCode);
          if (response.data.code == 0) {
            this.isGroupCodeValid = true;
            this.isGroupCodeInvalid = false;
            this.$refs.form.setErrors({groupCode: []});
          } else if (response.data.code == -2) {
            this.isGroupCodeInvalid = true;
            this.$refs.form.setErrors({groupCode: [response.data.message]});
          } else {
            this.isGroupCodeInvalid = true;
            this.$refs.form.setErrors({groupCode: ["Group Code can't be verified"]});
            console.log(response.data.message);
          }
          this.isBusy = false;
        } catch (error) {
          this.isBusy = false;
          console.log(error);
        }
      },

      clearGroupCode: function () {
        this.groupCode = "";
        this.isGroupCodeValid = false;
        this.$refs.form.setErrors({groupCode: []});
      }

    }
  }

</script>