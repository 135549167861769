import moment from 'moment'

export let ScholarshipApplication = {
  regType: "SCHOLARSHIP",
  scholarshipType: {
    engineeringCreativeAndEssay: 0,
    engineeringEssay: 0,
    stem: 0
  },
  firstName: "",
  lastName: "",
  middleInitial: "",
  streetAddress: "",
  streetAddressAdd: "",
  city: "",
  stateId: "",
  stateName: "",
  zip: "",
  email: "",
  dateOfBirth: "", //mm/dd/yyyy
  schoolId: "",
  schoolName: "",
  otherSchoolName: "",
  schoolStreetAddress: "",
  schoolCity: "",
  schoolStateId: "",
  schoolStateName: "",
  schoolZip: "",
  yearsAttendedFrom: "",
  yearsAttendedTo: "",
  gradePointAverage: "",
  notOn4Scale: 0,
  notOn4ScaleExplanation: "",
  activities: [],
  awards: [],
  events:[],
  universityToAttend: [],
  scoreReport: "", //attachment file name
  letterOfAcceptance: "", //attachment file name
  studyingProgram: "",
  isEmployed: "",
  workHoursPerWeek: "",
  employer: "",
  typeOfWork: "",
  hasPastEmployers: "",
  pastEmployers: [],
  essay: "",
  creativeComponent: "", //attachment file name
  creativeComponentURL: "",
  recommendation: "", //attachment file name
  isInformationFactual: 0,
  signature: "",
  signatureDate: moment().format('MM/DD/YYYY')
}

export const activityItem = Object.freeze({
  title: "",
  schoolYear9: 0,
  schoolYear10: 0,
  schoolYear11: 0,
  schoolYear12: 0,
  position: ""
})

export const awardItem = Object.freeze({
  title: "",
  schoolYear9: 0,
  schoolYear10: 0,
  schoolYear11: 0,
  schoolYear12: 0
})

export const eventItem = Object.freeze({
  title: "",
  schoolYear9: 0,
  schoolYear10: 0,
  schoolYear11: 0,
  schoolYear12: 0,
  date: ""
})

export const universityToAttendItem = Object.freeze({
  name: "",
  isAccepted: ""
})

export const pastEmployerItem = Object.freeze({
  jobTitle: "",
  jobDescription: "",
  schoolYear9: 0,
  schoolYear10: 0,
  schoolYear11: 0,
  schoolYear12: 0,
  employer: ""
})