<template>
  <div class="gr-border container px-3">
    <div class="text-end">
      <router-link :to="{name: 'hs-ind-reg-list'}"><button type="button" class="btn-close" aria-label="Close"></button></router-link>
    </div>
    <ValidationObserver ref="form" v-slot="{ valid }">
      <form>
        <fieldset :disabled="isBusy">
          <div class="row">
            <div class="text-center gr-bak col-sm-12">
              <h1 class="dk-blue upper"><strong>High School STEM Career Day {{$stemYear}}<br/>Registration</strong></h1>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>1. Student Information</h3>
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="first-name"><span class="text-danger">*</span>First Name</label>
                  <input type="text" name="first-name" id="first-name" class="form-control" :class="classes" v-model="formData.firstName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="last-name"><span class="text-danger">*</span>Last Name</label>
                  <input type="text" name="last-name" id="last-name" class="form-control" :class="classes" v-model="formData.lastName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider vid="email" rules="required|email" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="email"><span class="text-danger">*</span>Email Address</label>
                  <input type="email" name="email" id="email" class="form-control" :class="classes" v-model="formData.email" :disabled="isEdit">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider :rules="{ required: true, email: true, confirmEmail: '@email'}" v-if="!isEdit" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="email_confirm"><span class="text-danger">*</span>Confirm Email Address</label>
                  <input type="email" name="email_confirm" id="email_confirm" v-model="emailConfirm" class="form-control" :class="classes">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="hs-school-selector"><span class="text-danger">*</span>School</label>
                  <select id="hs-school-selector" name="hs-school-selector" class="form-select"  :class="classes" v-model="formData.schoolId">
                    <option value="">-Select Option-</option>
                    <option value="other">My school is not listed here. I will enter school in next field.</option>
                    <option v-for="school in schools" v-bind:value="school.id" v-bind:key="school.id">
                      {{ school.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-12">
              <!-- Quick fix to only show checkbox if the selected school is an Akron Public School -->
              <!-- Nice to have: cross-check against database: if school is Akron Public School -->
              <!-- Using TEST ids -->
              <div class="form-check form-check-inline ml-1">
                <input class="form-check-input" type="checkbox" id="consent-will-be-submitted-by-school" true-value="1" false-value="0" v-model="formData.consentWillBeSubmittedBySchool" v-show="formData.schoolId == '7d315482-c776-4c5c-b03c-93f69a1e2d73' || 
                  formData.schoolId == 'fd2fa8ea-3f14-47cf-a2cc-8b83120b2ae1' || 
                  formData.schoolId =='78afa38e-e7f7-42ed-9fb6-f909a54efc83' || 
                  formData.schoolId == 'c3ef4c39-f6bc-4f96-9dbf-22e327b414d5' || 
                  formData.schoolId == 'd63b3666-8024-47cd-906a-6f08ddc8f57c' || 
                  formData.schoolId == '85af5220-fa87-4a45-83f4-cf3d6cebadb8' || 
                  formData.schoolId == '3967a88a-2f3f-43c8-a914-8501f09ca8f8' || 
                  formData.schoolId == '2667bc6e-8ff5-45d0-a636-53885a3eb5d0'">
                <label class="form-check-label" for="consent-will-be-submitted-by-school" 
                v-show="formData.schoolId == '7d315482-c776-4c5c-b03c-93f69a1e2d73' || 
                  formData.schoolId == 'fd2fa8ea-3f14-47cf-a2cc-8b83120b2ae1' || 
                  formData.schoolId =='78afa38e-e7f7-42ed-9fb6-f909a54efc83' || 
                  formData.schoolId == 'c3ef4c39-f6bc-4f96-9dbf-22e327b414d5' || 
                  formData.schoolId == 'd63b3666-8024-47cd-906a-6f08ddc8f57c' || 
                  formData.schoolId == '85af5220-fa87-4a45-83f4-cf3d6cebadb8' || 
                  formData.schoolId == '3967a88a-2f3f-43c8-a914-8501f09ca8f8' || 
                  formData.schoolId == '2667bc6e-8ff5-45d0-a636-53885a3eb5d0' ">I'm an Akron Public School student whose parental consent form will be submitted by my school.</label>
              </div>
              <!-- Using PROD ids -->
              <div class="form-check form-check-inline ml-1">
                <input class="form-check-input" type="checkbox" id="consent-will-be-submitted-by-school" true-value="1" false-value="0" v-model="formData.consentWillBeSubmittedBySchool" v-show="formData.schoolId == '124df562-8494-40a8-bbd2-c341828cd812' || 
                  formData.schoolId == '371d2766-d6bf-4ca7-9b75-dd94da2f9190' || 
                  formData.schoolId == '43c44ef5-91c8-4155-8d56-fce4899ad9b4' || 
                  formData.schoolId == '33bee725-1970-4dbe-bb68-3d1f31ef7909' || 
                  formData.schoolId == '61cc62f4-8e81-40e3-b72d-b016ae4e9a5a' || 
                  formData.schoolId == '9d226e48-1700-4591-9d1a-4947d7969d44' || 
                  formData.schoolId == '3e2692bc-baf4-4a26-8933-afe00b864f98' || 
                  formData.schoolId == '5e29bfcf-79a4-45f9-adee-5322e5c12e7f' ">
                <label class="form-check-label" for="consent-will-be-submitted-by-school" 
                v-show="formData.schoolId == '124df562-8494-40a8-bbd2-c341828cd812' || 
                  formData.schoolId == '371d2766-d6bf-4ca7-9b75-dd94da2f9190' || 
                  formData.schoolId == '43c44ef5-91c8-4155-8d56-fce4899ad9b4' || 
                  formData.schoolId == '33bee725-1970-4dbe-bb68-3d1f31ef7909' || 
                  formData.schoolId == '61cc62f4-8e81-40e3-b72d-b016ae4e9a5a' || 
                  formData.schoolId == '9d226e48-1700-4591-9d1a-4947d7969d44' || 
                  formData.schoolId == '3e2692bc-baf4-4a26-8933-afe00b864f98' || 
                  formData.schoolId == '5e29bfcf-79a4-45f9-adee-5322e5c12e7f'" >I'm an Akron Public School student whose parental consent form will be submitted by my school.</label>
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider :rules="{required: formData.schoolId=='other' }" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="school-other"><span v-if="formData.schoolId=='other'" class="text-danger">*</span>School (If not in previous list)</label>
                  <input type="text" name="school-other" id="school-other" class="form-control" :class="classes" v-model="formData.otherSchoolName" :disabled="formData.schoolId !== 'other'">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="grade-level"><span class="text-danger">*</span>Grade Level</label>
                  <select id="grade-level" name="grade-level" class="form-select" :class="classes" v-model="formData.gradeLevelId">
                    <option value="" >-Select Option-</option>
                    <option v-for="gradeLevel in gradeLevels" v-bind:value="gradeLevel.id" v-bind:key="gradeLevel.id">
                      {{ gradeLevel.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="t-shirt-size"><span class="text-danger">*</span>T-Shirt Size</label>
                  <select id="t-shirt-size" name="t-shirt-size" class="form-select" :class="classes" v-model="formData.tShirtSizeId">
                    <option value="" >-Select Option-</option>
                    <option v-for="tShirtSize in tShirtSizes" v-bind:value="tShirtSize.id" v-bind:key="tShirtSize.id">
                      {{ tShirtSize.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-12">
              <strong><span class="text-danger">*</span>Have you attended a Goodyear STEM Day event in the past?</strong>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="has-attended" id="has-attended-yes" v-model="formData.hasAttended" value="1">
                <label class="form-check-label" for="has-attended-yes">Yes</label>
              </div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="has-attended" id="has-attended-no" v-model="formData.hasAttended" value="0">
                  <label class="form-check-label" for="has-attended-no">No</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
            <div class="col-12 pt-3">
              <p><strong><span class="text-danger">*</span>Preferred Tour type (Select up to 3):</strong></p>
            </div>
            <div class="col-12">
              <div class="card p-3">
                <div class="row">
                  <div class="form-group col-md-6">
                    <p>NEW Tours</p>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="aerospaceEngineering" true-value="1" false-value="0" v-model="formData.tourType.aerospaceEngineering">
                      <label class="form-check-label" for="aerospaceEngineering">Aerospace Engineering</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="stemInSports" true-value="1" false-value="0" v-model="formData.tourType.stemInSports">
                      <label class="form-check-label" for="stemInSports">STEM in Sports</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="nursingSimulations" true-value="1" false-value="0" v-model="formData.tourType.nursingSimulations">
                      <label class="form-check-label" for="nursingSimulations">Nursing Simulations</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="varsityESports" true-value="1" false-value="0" v-model="formData.tourType.varsityESports">
                      <label class="form-check-label" for="varsityESports">Varsity E-Sports</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="geosciences" true-value="1" false-value="0" v-model="formData.tourType.geosciences">
                      <label class="form-check-label" for="geosciences">Geosciences/Geology</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="honorsCollege" true-value="1" false-value="0" v-model="formData.tourType.honorsCollege">
                      <label class="form-check-label" for="honorsCollege">Honor's College</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="engineeringDesign" true-value="1" false-value="0" v-model="formData.tourType.engineeringDesign">
                      <label class="form-check-label" for="engineeringDesign">Engineering & Design</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="chemicalTransformations" true-value="1" false-value="0" v-model="formData.tourType.chemicalTransformations">
                      <label class="form-check-label" for="chemicalTransformations">Chemical Transformations</label>
                    </div>
                    <ValidationProvider name="tour type" :rules="{ atLeastOne: [formData.tourType.aerospaceEngineering, formData.tourType.biology, formData.tourType.chemistry, formData.tourType.construction, formData.tourType.computer, formData.tourType.electricalEngineering, formData.tourType.geosciences, formData.tourType.appliedMathematics, formData.tourType.physics, formData.tourType.medicine, formData.tourType.plastics, formData.tourType.eSports, formData.tourType.honorsCollege,formData.tourType.engineeringDesign,formData.tourType.chemicalTransformations, formData.tourType.stemInSports, formData.tourType.nursingSimulations, formData.tourType.varsityESports] }" v-slot="{errors}">
                      
                      <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-6">
                    <p>OLD Tours</p>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="electricalEngineering" true-value="1" false-value="0" v-model="formData.tourType.electricalEngineering">
                        <label class="form-check-label" for="electricalEngineering">Electrical Engineering</label>
                      </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="biology" true-value="1" false-value="0" v-model="formData.tourType.biology">
                      <label class="form-check-label" for="biology">Biology/Biomedical Engineering/Biomimicry</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="chemistry" true-value="1" false-value="0" v-model="formData.tourType.chemistry">
                      <label class="form-check-label" for="chemistry">Chemistry/Chemical Engineering/Corrosion Engineering</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="construction" true-value="1" false-value="0" v-model="formData.tourType.construction">
                      <label class="form-check-label" for="construction">Construction/Civil Engineering</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="computer" true-value="1" false-value="0" v-model="formData.tourType.computer">
                      <label class="form-check-label" for="computer">Computer Engineering/Computer Science/Cyber Security</label>
                    </div>
                    
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="appliedMathematics" true-value="1" false-value="0" v-model="formData.tourType.appliedMathematics">
                      <label class="form-check-label" for="appliedMathematics">Applied Mathematics/Statistics</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="physics" true-value="1" false-value="0" v-model="formData.tourType.physics">
                      <label class="form-check-label" for="physics">Materials Science/Mechanical Engineering/Physics</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="medicine" true-value="1" false-value="0" v-model="formData.tourType.medicine">
                      <label class="form-check-label" for="medicine">Medicine/Health Care</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="plastics" true-value="1" false-value="0" v-model="formData.tourType.plastics">
                      <label class="form-check-label" for="plastics">Plastics/Polymer Science/Polymer Engineering</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="eSports" true-value="1" false-value="0" v-model="formData.tourType.eSports">
                      <label class="form-check-label" for="eSports">E-Sports</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>2. Parent/Legal Guardian Information</h3>
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="parent-first-name"><span class="text-danger">*</span>First Name</label>
                  <input type="text" name="parent-first-name" id="parent-first-name" class="form-control" :class="classes" v-model="formData.parentGuardian.firstName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="parent-last-name"><span class="text-danger">*</span>Last Name</label>
                  <input type="text" name="parent-last-name" id="parent-last-name" class="form-control" :class="classes" v-model="formData.parentGuardian.lastName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider vid="parentEmail" rules="required|email" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="parent-email"><span class="text-danger">*</span>Email Address</label>
                  <input type="email" name="parent-email" id="parent-email" class="form-control" :class="classes" v-model="formData.parentGuardian.email">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider :rules="{ required: true, email: true, confirmEmail: '@parentEmail'}" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="parent-email-confirm"><span class="text-danger">*</span>Confirm Email Address</label>
                  <input type="email" name="parent-email-confirm" id="parent-email-confirm" class="form-control" :class="classes" v-model="parentEmailConfirm">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{classes, errors}">
                <div class="form-group">
                  <label for="parent-phone-number"><span class="text-danger">*</span>Phone Number</label>
                  <input type="text" name="parent-phone-number" id="parent-phone-number" class="form-control" :class="classes" v-model="formData.parentGuardian.phoneNumber">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <strong><span class="text-danger">*</span>Will this parent/legal guardian be attending?</strong>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="parent-attending" id="parent-attending-yes" v-model="formData.parentGuardian.willBeAttending" value="1">
                <label class="form-check-label" for="parent-attending-yes">Yes</label>
              </div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="parent-attending" id="parent-attending-no" v-model="formData.parentGuardian.willBeAttending" value="0">
                  <label class="form-check-label" for="parent-attending-no">No</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
            <div class="col-12" v-show="formData.parentGuardian.willBeAttending==1">
              <strong><span class="text-danger">*</span>Will an additional parent/legal guardian be attending?</strong>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="add-parent-attending" id="add-parent-attending-yes" v-model="formData.parentGuardian.willAddParentBeAttending" value="1">
                <label class="form-check-label" for="add-parent-attending-yes">Yes</label>
              </div>
              <ValidationProvider :rules="{required: formData.parentGuardian.willBeAttending==1}" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="add-parent-attending" id="add-parent-attending-no" v-model="formData.parentGuardian.willAddParentBeAttending" value="0">
                  <label class="form-check-label" for="add-parent-attending-no">No</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
              <div class="row" v-show="formData.parentGuardian.willAddParentBeAttending==1">
                <div class="col-md-6">
                  <ValidationProvider :rules="{required: formData.parentGuardian.willAddParentBeAttending==1}" v-slot="{classes, errors}">
                    <div class="form-group">
                      <label for="add-parent-first-name"><span class="text-danger">*</span>Additional Parent/Legal Guardian First Name</label>
                      <input type="text" name="add-parent-first-name" id="additional_prnt_fn" class="form-control" :class="classes" v-model="formData.parentGuardian.addParentFirstName"/>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="form-group col-md-6">
                  <ValidationProvider :rules="{required: formData.parentGuardian.willAddParentBeAttending==1}" v-slot="{classes, errors}">
                    <div class="form-group">
                      <label for="add-parent-last-name"><span class="text-danger">*</span>Additional Parent/Legal Guardian Last Name</label>
                      <input type="text" name="add-parent-last-name" id="additional_prnt_ln" class="form-control" :class="classes" v-model="formData.parentGuardian.addParentLastName"/>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>3. Emergency Contact for Day of Event</h3>
              </div>
            </div>
            <div class="col-12">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="ec-is-same-as-parent" name="ec-is-same-as-parent" v-model="isParentEmergencyContact">
                <label class="form-check-label" for="">Same as Parent/Legal Guardian information</label>
              </div>
            </div>
            <div class="col-12" v-if="!isParentEmergencyContact">
              <div class="row">
                <div class="col-md-6">
                  <ValidationProvider rules="required" v-slot="{classes, errors}">
                    <div class="form-group">
                      <label for="ec-first-name"><span class="text-danger">*</span>First Name</label>
                      <input type="text" name="ec-first-name" id="ec-first-name" class="form-control" :class="classes" v-model="formData.emergencyContact.firstName">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider rules="required" v-slot="{classes, errors}">
                    <div class="form-group">
                      <label for="ec-last-name"><span class="text-danger">*</span>Last Name</label>
                      <input type="text" name="ec-last-name" id="ec-last-name" class="form-control" :class="classes" v-model="formData.emergencyContact.lastName">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider rules="required" v-slot="{classes, errors}">
                    <div class="form-group">
                      <label for="ec-phone-number"><span class="text-danger">*</span>Phone Number</label>
                      <input type="text" name="ec-phone-number" id="ec-phone-number" class="form-control" :class="classes" v-model="formData.emergencyContact.phoneNumber">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>4. STEM Day consent form</h3>
              </div>
            </div>
            <div class="form-group col-12">
              <strong><span class="text-danger">*</span>Is a parent/legal guardian present to complete the STEM Day consent form?</strong>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="consent-is-prnt-present" id="consent-is-prnt-present-yes" v-model="isContestFormSubmitted" v-bind:value="true">
                <label class="form-check-label" for="consent-is-prnt-present-yes">Yes</label>
              </div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="consent-is-prnt-present" id="consent-is-prnt-present-no" v-model="isContestFormSubmitted" v-bind:value="false">
                  <label class="form-check-label" for="consent-is-prnt-present-no">No</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
            <div class="col-12">
              <!-- <div class="form-check form-check-inline ml-1">
                <input class="form-check-input" type="checkbox" id="consent-will-be-submitted-by-school" true-value="1" false-value="0" v-model="formData.consentWillBeSubmittedBySchool">
                <label class="form-check-label" for="consent-will-be-submitted-by-school">I'm an Akron Public School student whose parental consent form will be submitted by my school.</label>
              </div> -->
              <p class="small">
                <i>You must have a parent/legal guardian fill out the consent form to submit your registration.</i>
              </p>
            </div>
            <div class="form-group col-12" v-if="isContestFormSubmitted && formData.consentWillBeSubmittedBySchool!='1'">
              <p><strong><span class="text-danger">*</span>Parent/Legal Guardian please view and sign the consent form below:</strong></p>
              <div class="consent-form-container">
                <h3>Consent and Release Form</h3>
                <p>I, <ValidationProvider :rules="{required: isContestFormSubmitted}" v-slot="{classes}"><input type="text" name="consent-name" id="consent-name" class="form-control consent-content" :class="classes" placeholder="*Name of Parent/Guardian" v-model="formData.stemDayConsent.parentGuardianName" /></ValidationProvider>, am the 
                <ValidationProvider :rules="{required: isContestFormSubmitted}" v-slot="{classes}"><input type="text" name="consent-relation" id="consent-relation" class="form-control consent-content" :class="classes" placeholder="*Father, Mother, Custodial Parent, Legal Guardian" v-model="formData.stemDayConsent.relation" /></ValidationProvider> of 
                <ValidationProvider :rules="{required: isContestFormSubmitted}" v-slot="{classes}"><input type="text" name="consent-student-name" id="consent-student-name" class="form-control consent-content" :class="classes" placeholder="*Student's Name" v-model="formData.stemDayConsent.studentName" /></ValidationProvider>.</p>
                <p>The above-mentioned child is permitted to participate in Goodyear's STEM Career Day at the University of Akron. On behalf of my child and myself, I hereby assume all risks in connection with Goodyear's STEM Career Day and I further release, discharge, and/or otherwise indemnify The Goodyear Tire &amp; Rubber Company and The University of Akron, as well as their officers, employees and volunteers from all claims, judgments, liability by or on behalf of myself and my child for any injury or damage due to the child's participation in Goodyear's STEM Career Day at the University of Akron, including all risks connected therewith whether foreseen or unforeseen.</p>
                <p>I hereby acknowledge that all registration information may be shared with the University of Akron for the purpose of scheduling.</p>
								<p>I hereby acknowledge that the materials submitted by my child to The Goodyear Tire &amp; Rubber Company as part of an application in the Rube Goldberg Challenge<sup>™</sup> for Goodyear’s STEM Career Day, including any video which may include footage of my child, may appear on a Goodyear website or be otherwise distributed in some form of social media.</p>
								<p>I hereby acknowledge that my child is going to be given access to an account on the website for event participants with the opportunity to log in and change the registration information and password.</p>
								<p>The Goodyear Tire &amp; Rubber Company and its associated companies and The University of Akron may make or permit use of, and may copyright, for publicity or other business purposes, the photographs or video taken on or prior to Goodyear’s STEM Career Day of me and/or my son/daughter/legal ward and/or make or permit use of my (or their) written or recorded statements, either verbatim or edited or abridged, in letter form or otherwise, all in such form and manner as said The Goodyear Tire &amp; Rubber Company and The University of Akron may desire and either in conjunction with my name or their name or apart from it.</p>
                <p><ValidationProvider :rules="{required: isContestFormSubmitted}" v-slot="{classes}"><input type="text" name="consent-signature" id="consent-signature" class="form-control consent-content" :class="classes" v-model="formData.stemDayConsent.parentGuardianElSignature" placeholder="*Parent/Legal Guardian's Electronic Signature"/></ValidationProvider> <ValidationProvider :rules="{required: isContestFormSubmitted, validDate: 'MM/DD/YYYY'}" v-slot="{classes}"><input type="text" name="consent-date" id="consent-date" class="form-control consent-content" :class="classes" v-model="formData.stemDayConsent.date" readonly /></ValidationProvider></p>
              </div>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-md-4">
              <button class="btn btn-yellow" v-on:click="edit" v-if="isEdit">UPDATE REGISTRATION</button>
            </div>
            <div v-if="isBusy" class="col-md-4 align-self-center text-center">
              <b-spinner></b-spinner>
            </div>
            <div v-else class="col-md-4 mt-2">
              <p v-if="!valid && showErrorMsg"><strong><span class="text-danger">Please check all required fields</span></strong></p>
            </div>
            <div class="col-md-4">
              <router-link :to="{name: 'hs-ind-reg-list'}"><button class="btn btn-yellow">CANCEL</button></router-link>
            </div>
          </div>
        </fieldset>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import moment from 'moment';
  import HSIndRegistration from '../models/hs-ind-registration';
  import dataService from '../services/data.service';
  import { hsGradeLevels } from '../data/grade-levels'
  import { tShirtSizes } from '../data/tshirt-sizes'

  export default {
    props: ['regId','email','ageVerified'],
    data: function() {
      return {
        isBusy: false,
        isEdit: false,
        showErrorMsg: false,
        schools: [],
        gradeLevels: hsGradeLevels,
        tShirtSizes: tShirtSizes,
        formData: JSON.parse(JSON.stringify(HSIndRegistration)), //clone to avoid caching, doesn't work for complex types (e.g. Date)
        emailConfirm: "",
        parentEmailConfirm: "",
        isParentEmergencyContact: false,
        isContestFormSubmitted: ""
      };
    },
    async mounted (){
      this.isBusy = true;
      try {
        if(this.$route.params.regId) {
          this.isEdit = true;
          let regData = await dataService.getRegistrationData(this.$route.params.regId, this.$route.params.email);
          this.formData = regData.data.data;
          this.parentEmailConfirm = this.formData.parentGuardian.email;
          this.isContestFormSubmitted = this.formData.stemDayConsent.parentGuardianName ? true : false;
          if (!this.formData.stemDayConsent.date) {
            this.formData.stemDayConsent.date = moment().format('MM/DD/YYYY');
          }
        } else {
          this.$router.push({ name: 'hs-ind-reg-list' });
        }

        let schoolList = await dataService.getCatalogItemsByCategory("HIGHSCH");
        this.schools = schoolList.data.data;

        this.isBusy = false;

      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },
    methods: {
      edit: async function (event) {
        event.preventDefault();
        this.isBusy = true;

        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showErrorMsg = true;
          this.isBusy = false;
          return;
        }

        this.clearHiddenFields();

        if (this.isParentEmergencyContact) {
          this.setParentAsEmergencyContact();
        }

        try {
          await dataService.updateHSIndReg(this.$route.params.regId, this.formData);
          this.$router.push({ name: 'hs-ind-reg-list', params: {refreshTable: true}});
        } catch (error) {
          this.isBusy = false;
          console.log(error);
        }
      },

      setParentAsEmergencyContact: function() {
        this.formData.emergencyContact.firstName = this.formData.parentGuardian.firstName;
        this.formData.emergencyContact.lastName = this.formData.parentGuardian.lastName;
        this.formData.emergencyContact.phoneNumber = this.formData.parentGuardian.phoneNumber;
      },

      clearHiddenFields: function () {
        if (this.formData.schoolId != 'other') {
          this.formData.otherSchoolName = "";
        }

        if (this.formData.parentGuardian.willBeAttending != 1) {
          this.formData.parentGuardian.willAddParentBeAttending = "";
        }
        if (this.formData.parentGuardian.willAddParentBeAttending != 1) {
          this.formData.parentGuardian.addParentFirstName = "";
          this.formData.parentGuardian.addParentLastName = "";
        }

        if (!this.isContestFormSubmitted || this.formData.consentWillBeSubmittedBySchool=='1') {
          this.formData.stemDayConsent.parentGuardianName = "";
          this.formData.stemDayConsent.relation = "";
          this.formData.stemDayConsent.studentName = "";
          this.formData.stemDayConsent.parentGuardianElSignature = "";
          this.formData.stemDayConsent.date = "";
        }
      }

    }
  }

</script>