import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import Forbidden from './views/403.vue'
import MSIndRegList from './views/MSIndRegList.vue'
import MSIndReg from './views/MSIndReg.vue'
import MSGroupRegList from './views/MSGroupRegList.vue'
import MSGroupReg from './views/MSGroupReg.vue'
import MSGroupMembersPendingList from './views/MSGroupMembersPendingList.vue'
import MSGroupMembersPending from './views/MSGroupMembersPending.vue'
import HSIndRegList from './views/HSIndRegList.vue'
import HSIndReg from './views/HSIndReg.vue'
import HSChaperoneRegList from './views/HSChaperoneRegList.vue'
import HSChaperoneReg from './views/HSChaperoneReg.vue'
import CompanyRegList from './views/CompanyRegList.vue'
import CompanyReg from './views/CompanyReg.vue'
import ScholarshipApplicationList from './views/ScholarshipApplicationList.vue'
import ScholarshipApplication from './views/ScholarshipApplication.vue'
import Logout from './views/Logout.vue'
import AttachmentDownload from './views/AttachmentDownload.vue'
import { msalPluginInstance } from "./plugins/MSALPlugin"
import { roles } from "./authConfig"

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/403', name: 'forbidden', component: Forbidden },
  { path: '/logout', name: 'logout', component: Logout },
  //{ path: '/404', name: 'notfound', component: NotFound },
  { path: '/ms-ind-reg-list', name: 'ms-ind-reg-list',  component: MSIndRegList, props: true,
    children: [
      {
        path: '/ms-ind-reg-edit',
        name: 'ms-ind-reg-edit',
        component: MSIndReg,
        props: true,
        meta: {
          showModal: true
        }
      }
    ]
  },
  { path: '/ms-group-reg-list', name: 'ms-group-reg-list',  component: MSGroupRegList, props: true,
    children: [
      {
        path: '/ms-group-reg-edit',
        name: 'ms-group-reg-edit',
        component: MSGroupReg,
        props: true,
        meta: {
          showModal: true
        }
      }
    ]
  },
  { path: '/ms-group-members-pending-list', name: 'ms-group-members-pending-list',  component: MSGroupMembersPendingList,
    children: [
      {
        path: '/ms-group-members-pending-edit',
        name: 'ms-group-members-pending-edit',
        component: MSGroupMembersPending,
        props: true,
        meta: {
          showModal: true
        }
      }
    ]
  },
  { path: '/hs-ind-reg-list', name: 'hs-ind-reg-list',  component: HSIndRegList, props: true,
    children: [
      {
        path: '/hs-ind-reg-edit',
        name: 'hs-ind-reg-edit',
        component: HSIndReg,
        props: true,
        meta: {
          showModal: true
        }
      }
    ]
  },
  { path: '/hs-chaperone-reg-list', name: 'hs-chaperone-reg-list',  component: HSChaperoneRegList, props: true,
    children: [
      {
        path: '/hs-chaperone-reg-edit',
        name: 'hs-chaperone-reg-edit',
        component: HSChaperoneReg,
        props: true,
        meta: {
          showModal: true
        }
      }
    ]
  },
  { path: '/company-reg-list', name: 'company-reg-list',  component: CompanyRegList, props: true,
    children: [
      {
        path: '/company-reg-edit',
        name: 'company-reg-edit',
        component: CompanyReg,
        props: true,
        meta: {
          showModal: true
        }
      }
    ]
  },
  { path: '/scholarship-list', name: 'scholarship-list',  component: ScholarshipApplicationList, props: true,
    children: [
      {
        path: '/scholarship-edit',
        name: 'scholarship-edit',
        component: ScholarshipApplication,
        props: true,
        meta: {
          showModal: true
        }
      }
    ]
  },
  { path: '/attachment-download', name: 'attachment-download', component: AttachmentDownload }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.name == 'forbidden' || to.name == 'logout') {
    next()
  } else {
    await msalPluginInstance.signIn();
    if(msalPluginInstance.isAuthenticated()) {
      if(msalPluginInstance.isAuthorized(roles.ADMIN)) {
        next();
      } else {
        next({name: 'forbidden'});
      }
    }
  }

})

Vue.use(VueRouter)

export default router