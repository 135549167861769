export const msGradeLevels = [
    { id: "1", title: "6th", "isActive": 1 },
    { id: "2", title: "7th", "isActive": 1 },
    { id: "3", title: "8th", "isActive": 1 }
]

export const hsGradeLevels = [
    { id: "1", title: "9th", "isActive": 1 },
    { id: "2", title: "10th", "isActive": 1 },
    { id: "3", title: "11th", "isActive": 1 },
    { id: "4", title: "12th", "isActive": 1 }
]