<template>
  <div>
    <Header/>
    <div class="d-none d-lg-block" v-if="$msal.isAuthenticated()">
      <div class="sidenav">
        <Menu/>
      </div>
    </div>
    <div class="main-content">
      <router-view/>
    </div>
  </div>
</template>

<script>
  import Header from './components/Header.vue'
  import Menu from './components/Menu.vue'

  export default {
    components: {
      Header,
      Menu
    },
    name: "stem-career-day-admin",
  }
</script>
