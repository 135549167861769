import moment from 'moment'

export default {
  regType: "HSCHAPERONE",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  stemDayConsent: {
    chaperoneFullName: "",
    chaperoneSignature: "",
    date: moment().format('MM/DD/YYYY')
  }
}